import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { TextBlockData } from 'theme/stores/cms/types';
import { decodePhpHtmlString } from '@zento-lib/util/html';

import { InnerHTML } from '../../../atom/InnerHTML';

import type { ITextBlock } from './TextBlock.d';
import style from './style.scss';

/**
 * Text Block
 *
 * Renders a custom text (specified by the shop administrator)
 */
@Component({})
export class TextBlock extends BaseComponent<ITextBlock, unknown> {
  /**
   * An object containing component information (like title, text description.)
   */
  @Prop({ type: Object, required: true })
  textBlockData: TextBlockData;

  render() {
    return (
      <div class={style.textBlock} key={this.textBlockData.heading ?? 'text-block-content'}>
        {this.textBlockData.heading ? (
          <h2
            class={{
              [style.centerTitle]: this.textBlockData.heading_position === 'center',
              [style.rightTitle]: this.textBlockData.heading_position === 'right',
            }}>
            {this.textBlockData.heading}
          </h2>
        ) : null}

        <InnerHTML contents={decodePhpHtmlString(this.textBlockData.text ?? this.textBlockData.content)} key='text' />
      </div>
    );
  }
}
